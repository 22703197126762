<template>
	<div>
		<ValidationObserver ref="formModalValidate">
			<b-row>
				<b-col cols="12">
					<ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
						<b-form-group :label="$t('explanation')">
                            <b-form-input v-model="formData.explanation" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
						</b-form-group>
					</ValidationProvider>
				</b-col>
                <b-col cols="6">
                    <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('date')">
                        <v-date-picker
                            v-model="formData.date"
                            locale="tr"
                            is-expanded
                            :masks="{ input: 'YYYY-MM-DD'}"
                            :popover="{ 'visibility': 'click' }">
                            <template
                                v-slot="{ inputValue, inputEvents }">
                                <b-input-group>
                                    <b-form-input
                                        :readonly="true"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                        :state="errors[0] ? false : null"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-button
                                            variant="outline-secondary"
                                            class="btn-40"
                                            disabled
                                        >
                                            <i class="ri-calendar-line"></i
                                            ></b-button>
                                    </b-input-group-append>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-input-group>
                            </template>
                        </v-date-picker>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
			</b-row>

			<div class="d-flex justify-content-center mt-2">
				<b-button variant="primary"
				          @click="createForm"
				          :disabled="formLoading">
					{{ $t('save') }}
				</b-button>
			</div>
		</ValidationObserver>
	</div>
</template>

<script>

	// Services

	// Other
	import {ValidationProvider, ValidationObserver} from "vee-validate"
    import HolidayServices from "@/services/HolidayServices";
    import moment from "moment";

	export default {
		components: {
			ValidationProvider,
			ValidationObserver,
		},
		data() {
			return {
				formData: {},
				formLoading: false,
			}
		},
		methods: {
			async createForm() {
                    const isValid = await this.$refs.formModalValidate.validate();
                    if (isValid) {
                        this.formLoading = true;
                        this.formData.date = moment(this.formData.date).format('YYYY-MM-DD');
                        HolidayServices.store(this.formData)
                                     .then(response => {
                                         this.$toast.success(this.$t('api.' + response.data.message));
                                         this.$emit('createFormSuccess', true);
                                     })
                                     .catch(e => {
                                         this.showErrors(e, this.$refs.formModalValidate);
                                     })
                                     .finally(() => {
                                         this.formLoading = false
                                     })
                    }
                }
		}
	}
</script>

